import {
  SIDEBAR_CLOSED,
  SIDEBAR_OPEN,
  SIDEBAR_TOGGLE,
  THEME_DARK,
  THEME_LIGHT,
  THEME_TOGGLE,
  ThemeState,
} from 'modules/theme/types'

export const initThemeState = {
  mode: 'light',
  sidebarOpen: false,
}

interface Action {
  type?: string
  payload?: unknown
}

export const ThemeReducer = (
  state: ThemeState = initThemeState,
  action: Action = {}
): ThemeState => {
  const { type } = action

  switch (type) {
    case THEME_TOGGLE:
      return {
        ...state,
        mode: state.mode === 'dark' ? 'light' : 'dark',
      }

    case THEME_LIGHT:
      return {
        ...state,
        mode: 'light',
      }

    case THEME_DARK:
      return {
        ...state,
        mode: 'dark',
      }
    case SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
      }

    case SIDEBAR_CLOSED:
      return {
        ...state,
        sidebarOpen: false,
      }

    case SIDEBAR_OPEN:
      return {
        ...state,
        sidebarOpen: true,
      }
    default:
      return state
  }
}
