const config = {
  Auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_ksUP3AMVI',
    userPoolWebClientId: '7jofedtbbhg45fllmicvd8f3n1',
  },
  API: {
    endpoints: [
      {
        name: 'RoomsService',
        endpoint: 'https://api.getaway.fi.hakio.fi/rooms',
      },
      {
        name: 'OperatorsService',
        endpoint: 'https://api.getaway.fi.hakio.fi/operators',
      },
      {
        name: 'DevicesService',
        endpoint: 'https://api.getaway.fi.hakio.fi/devices',
      },
      {
        name: 'GameClockService',
        endpoint: 'https://api.getaway.fi.hakio.fi/gameclock',
      },
      {
        name: 'TipsService',
        endpoint: 'https://api.getaway.fi.hakio.fi/tips',
      },
    ],
  },
}

export default config
