import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { load, save } from 'redux-localstorage-simple'
import { createStore, applyMiddleware } from 'redux'
import combinedReducers, { AppState } from './rootReducer'
import { initThemeState } from 'modules/theme/reducers'
import { initRoomState, initRoomsState } from 'modules/room/state/reducers'
import { initDevicesState } from 'modules/devices/state/reducers'
import { initMessagesState } from 'modules/messages/state/reducers'
import { initUserState } from 'modules/user/state/reducers'
import { initGameClockState } from 'modules/gameClock/state/reducers'
import { initGameTipState } from 'modules/gameTip/state/reducers'
import { initGameHistoryState } from 'modules/gameHistory/state/reducers'

const middlewares = [
  thunk,
  save({ states: ['theme', 'rooms', 'devices', 'user'] }),
]

const init: AppState = {
  theme: initThemeState,
  rooms: initRoomsState,
  devices: initDevicesState,
  currentRoom: initRoomState,
  messages: initMessagesState,
  user: initUserState,
  gameClock: initGameClockState,
  gameTip: initGameTipState,
  gameHistory: initGameHistoryState,
}

export default (preloadedState: AppState = init) => {
  return createStore(
    combinedReducers,
    getLoadedState(preloadedState),
    composeWithDevTools(applyMiddleware(...middlewares))
  )
}

const getLoadedState = (preloadedState: AppState) => {
  if (typeof window !== 'undefined')
    return {
      ...preloadedState,
      ...load({
        states: ['theme', 'rooms', 'devices', 'user'],
        disableWarnings: true,
      }),
    }

  return {
    ...preloadedState,
  }
}
