export const THEME_TOGGLE = 'THEME_TOGGLE'
export const THEME_LIGHT = 'THEME_LIGHT'
export const THEME_DARK = 'THEME_DARK'
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE'
export const SIDEBAR_OPEN = 'SIDEBAR_OPEN'
export const SIDEBAR_CLOSED = 'SIDEBAR_CLOSED'

export interface ThemeState {
  mode: string
  sidebarOpen: boolean
}
