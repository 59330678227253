import { GameTip } from 'types/gameTip'

export enum GameTipActionTypes {
  FETCH_REQUEST = '@@gameTip/FETCH_REQUEST',
  FETCH_SUCCESS = '@@gameTip/FETCH_SUCCESS',
  FETCH_ERROR = '@@gameTip/FETCH_ERROR',
  FETCH_ROOM_REQUEST = '@@gameTip/FETCH_ROOM_REQUEST',
  FETCH_ROOM_SUCCESS = '@@gameTip/FETCH_ROOM_SUCCESS',
  FETCH_ROOM_ERROR = '@@gameTip/FETCH_ROOM_ERROR',
  CREATE_REQUEST = '@@gameTip/CREATE_REQUEST',
  CREATE_SUCCESS = '@@gameTip/CREATE_SUCCESS',
  CREATE_ERROR = '@@gameTip/CREATE_ERROR',
  EDIT_REQUEST = '@@gameTip/EDIT_REQUEST',
  EDIT_SUCCESS = '@@gameTip/EDIT_SUCCESS',
  EDIT_ERROR = '@@gameTip/EDIT_ERROR',
  REMOVE_REQUEST = '@@gameTip/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@@gameTip/REMOVE_SUCCESS',
  REMOVE_ERROR = '@@gameTip/REMOVE_ERROR',
}

export interface GameTipState {
  readonly loading: boolean
  readonly data: GameTip[] | null
  readonly errors?: string
}
