import { Reducer } from 'redux'
import {
  RoomActionTypes,
  RoomState,
  RoomsActionTypes,
  RoomsState,
} from 'modules/room/state/types'
import { Operator } from 'types/operator'
import { sortOperators } from 'modules/room/lib/operatorHelpers'

export const initRoomsState: RoomsState = {
  data: [],
  errors: undefined,
  loading: false,
}

export const RoomsReducer: Reducer<RoomsState> = (
  state = initRoomsState,
  action
) => {
  switch (action.type) {
    case RoomsActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true }
    }
    case RoomsActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    case RoomsActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    default: {
      return state
    }
  }
}

export const initRoomState: RoomState = {
  data: null,
  errors: undefined,
  isDesignerMode: false,
  isPolling: false,
  loading: false,
}

export const RoomReducer: Reducer<RoomState> = (
  state = initRoomState,
  action
) => {
  switch (action.type) {
    case RoomActionTypes.FETCH_REQUEST: {
      let isDesignerMode = state.isDesignerMode
      if (
        typeof state.data?.id !== 'undefined' &&
        action.payload.id !== undefined &&
        state.data.id !== action.payload.id
      ) {
        isDesignerMode = false
      }

      return { ...state, isDesignerMode, loading: true }
    }
    case RoomActionTypes.FETCH_SUCCESS: {
      const operators = (action.payload.operators as any) as Operator[]

      return {
        ...state,
        loading: false,
        data: { ...action.payload, operators: sortOperators(operators) },
      }
    }
    case RoomActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    case RoomActionTypes.CREATE_REQUEST: {
      return { ...state, loading: true }
    }
    case RoomActionTypes.CREATE_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    case RoomActionTypes.CREATE_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    case RoomActionTypes.EDIT_REQUEST: {
      return { ...state, loading: true }
    }
    case RoomActionTypes.EDIT_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    case RoomActionTypes.EDIT_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    case RoomActionTypes.CREATE_OPERATOR_REQUEST: {
      return { ...state, loading: true }
    }
    case RoomActionTypes.CREATE_OPERATOR_SUCCESS: {
      let operators
      if (typeof state.data?.operators !== 'undefined') {
        operators = ([
          ...state.data.operators,
          action.payload.operator,
        ] as any) as Operator[]
      } else {
        operators = ([action.payload.operator] as any) as Operator[]
      }
      return {
        ...state,
        loading: false,
        data: Object.assign({}, state.data, {
          operators: sortOperators(operators),
        }),
      }
    }
    case RoomActionTypes.CREATE_OPERATOR_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    case RoomActionTypes.EDIT_OPERATOR_REQUEST: {
      return { ...state, loading: true }
    }
    case RoomActionTypes.EDIT_OPERATOR_SUCCESS: {
      if (null !== state.data && typeof state.data.operators !== 'undefined') {
        const existingOperator = state.data.operators.find(
          operator => operator.id === action.payload.operator.id
        )
        if (typeof existingOperator !== 'undefined') {
          const newOperator = Object.assign(
            {},
            existingOperator,
            action.payload.operator
          )
          const operators = [
            ...state.data.operators.filter(
              operator => operator.id !== action.payload.operator.id
            ),
            newOperator,
          ]
          return {
            ...state,
            loading: false,
            data: Object.assign({}, state.data, {
              operators: sortOperators(operators),
            }),
          }
        }
      }
      return { ...state, loading: false }
    }
    case RoomActionTypes.EDIT_OPERATOR_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    case RoomActionTypes.BATCH_EDIT_OPERATOR_REQUEST: {
      return { ...state, loading: true }
    }
    case RoomActionTypes.BATCH_EDIT_OPERATOR_SUCCESS: {
      const operators: Array<Operator> = [...action.payload.operators]
      return {
        ...state,
        loading: false,
        data: { ...state.data, operators: sortOperators(operators) },
      }
    }
    case RoomActionTypes.SET_RELAYS_REQUEST: {
      return { ...state, loading: true }
    }
    case RoomActionTypes.SET_RELAYS_SUCCESS: {
      return { ...state, loading: false }
    }
    case RoomActionTypes.SET_RELAYS_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    case RoomActionTypes.SET_IS_DESIGNER_MODE: {
      return {
        ...state,
        isDesignerMode: action.payload,
      }
    }
    case RoomActionTypes.ADD_EXTRA_DEVICES_REQUEST: {
      return { ...state, loading: true }
    }
    case RoomActionTypes.ADD_EXTRA_DEVICES_SUCCESS: {
      return { ...state, loading: false }
    }
    case RoomActionTypes.ADD_EXTRA_DEVICES_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    case RoomActionTypes.SET_IS_POLLING: {
      return {
        ...state,
        isPolling: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
