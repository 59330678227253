import { Reducer } from 'redux'
import { GameTipActionTypes, GameTipState } from './types'

export const initGameTipState: GameTipState = {
  data: [],
  errors: undefined,
  loading: false,
}

export const GameTipReducer: Reducer<GameTipState> = (
  state = initGameTipState,
  action
) => {
  switch (action.type) {
    case GameTipActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true }
    }
    case GameTipActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    case GameTipActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    case GameTipActionTypes.FETCH_ROOM_REQUEST: {
      return { ...state, loading: true }
    }
    case GameTipActionTypes.FETCH_ROOM_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    case GameTipActionTypes.FETCH_ROOM_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    default: {
      return state
    }
  }
}
