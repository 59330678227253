import { Reducer } from 'redux'
import { GameHistoryActionTypes, GameHistoryState } from './types'

export const initGameHistoryState: GameHistoryState = {
  data: null,
  errors: undefined,
  loading: false,
}

const GameHistoryReducer: Reducer<GameHistoryState> = (
  state = initGameHistoryState,
  action
) => {
  switch (action.type) {
    case GameHistoryActionTypes.FETCH_LIST_REQUEST: {
      return { ...state, loading: true }
    }
    case GameHistoryActionTypes.FETCH_LIST_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    case GameHistoryActionTypes.FETCH_LIST_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    default: {
      return state
    }
  }
}

export { GameHistoryReducer }
