import { Operator } from 'types/operator'

export const sortOperators = (operators: Operator[] | undefined) => {
  if (typeof operators === 'undefined') {
    return undefined
  }
  return operators.sort((a, b) => {
    if (typeof a.order !== 'undefined' && typeof b.order !== 'undefined') {
      if (a.order < b.order) return -1
      return a.order > b.order ? 1 : 0
    }
    if (a.name < b.name) return -1
    return a.name > b.name ? 1 : 0
  })
}
