import { GameClock } from 'types/gameClock'

export enum GameHistoryActionTypes {
  FETCH_LIST_REQUEST = '@@gameHistory/FETCH_LIST_REQUEST',
  FETCH_LIST_SUCCESS = '@@gameHistory/FETCH_LIST_SUCCESS',
  FETCH_LIST_ERROR = '@@gameHistory/FETCH_LIST_ERROR',
}
export interface GameHistoryState {
  readonly loading: boolean
  readonly data: GameClock[] | null
  readonly errors?: string
}
