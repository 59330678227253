import { combineReducers } from 'redux'
import { ThemeReducer } from 'modules/theme/reducers'
import { RoomsReducer, RoomReducer } from 'modules/room/state/reducers'
import { ThemeState } from 'modules/theme/types'
import { RoomState, RoomsState } from 'modules/room/state/types'
import { DevicesState } from 'modules/devices/state/types'
import { DevicesReducer } from 'modules/devices/state/reducers'
import { MessagesState } from 'modules/messages/state/types'
import { MessagesReducer } from 'modules/messages/state/reducers'
import { UserState } from 'modules/user/state/types'
import { UserReducer } from 'modules/user/state/reducers'
import { GameClockReducer } from 'modules/gameClock/state/reducers'
import { GameClockState } from 'modules/gameClock/state/types'
import { GameTipReducer } from 'modules/gameTip/state/reducers'
import { GameTipState } from 'modules/gameTip/state/types'
import { GameHistoryState } from 'modules/gameHistory/state/types'
import { GameHistoryReducer } from 'modules/gameHistory/state/reducers'

export interface AppState {
  theme: ThemeState
  rooms: RoomsState
  devices: DevicesState
  currentRoom: RoomState
  messages: MessagesState
  user: UserState
  gameClock: GameClockState
  gameTip: GameTipState
  gameHistory: GameHistoryState
}

export default combineReducers({
  theme: ThemeReducer,
  rooms: RoomsReducer,
  devices: DevicesReducer,
  currentRoom: RoomReducer,
  messages: MessagesReducer,
  user: UserReducer,
  gameClock: GameClockReducer,
  gameTip: GameTipReducer,
  gameHistory: GameHistoryReducer,
})
