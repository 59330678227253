export interface UserState {
  isLoggedIn: boolean
  isAdmin: boolean
}
export enum UserActionTypes {
  LOGIN_REQUEST = '@@user/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@@user/LOGIN_SUCCESS',
  LOGOUT = '@@user/LOGOUT',
  SET_IS_ADMIN = '@@user/SET_IS_ADMIN',
}
