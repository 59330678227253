import { Room } from 'types/room'

export enum RoomActionTypes {
  FETCH_REQUEST = '@@room/FETCH_REQUEST',
  FETCH_SUCCESS = '@@room/FETCH_SUCCESS',
  FETCH_ERROR = '@@room/FETCH_ERROR',
  CREATE_REQUEST = '@@room/CREATE_REQUEST',
  CREATE_SUCCESS = '@@room/CREATE_SUCCESS',
  CREATE_ERROR = '@@room/CREATE_ERROR',
  EDIT_REQUEST = '@@room/EDIT_REQUEST',
  EDIT_SUCCESS = '@@room/EDIT_SUCCESS',
  EDIT_ERROR = '@@room/EDIT_ERROR',
  CREATE_OPERATOR_REQUEST = '@@room/CREATE_OPERATOR_REQUEST',
  CREATE_OPERATOR_SUCCESS = '@@room/CREATE_OPERATOR_SUCCESS',
  CREATE_OPERATOR_ERROR = '@@room/CREATE_OPERATOR_ERROR',
  EDIT_OPERATOR_REQUEST = '@@room/EDIT_OPERATOR_REQUEST',
  EDIT_OPERATOR_SUCCESS = '@@room/EDIT_OPERATOR_SUCCESS',
  EDIT_OPERATOR_ERROR = '@@room/EDIT_OPERATOR_ERROR',
  REMOVE_OPERATOR_REQUEST = '@@room/REMOVE_OPERATOR_REQUEST',
  REMOVE_OPERATOR_SUCCESS = '@@room/REMOVE_OPERATOR_SUCCESS',
  REMOVE_OPERATOR_ERROR = '@@room/REMOVE_OPERATOR_ERROR',
  BATCH_EDIT_OPERATOR_REQUEST = '@@room/BULK_EDIT_OPERATOR_REQUEST',
  BATCH_EDIT_OPERATOR_SUCCESS = '@@room/BULK_EDIT_OPERATOR_SUCCESS',
  BATCH_EDIT_OPERATOR_ERROR = '@@room/BULK_EDIT_OPERATOR_ERROR',
  SET_RELAYS_REQUEST = '@@room/SET_RELAYS_REQUEST',
  SET_RELAYS_SUCCESS = '@@room/SET_RELAYS_SUCCESS',
  SET_RELAYS_ERROR = '@@room/SET_RELAYS_ERROR',
  SET_IS_DESIGNER_MODE = '@@room/SET_IS_DESIGNER_MODE',
  ADD_EXTRA_DEVICES_REQUEST = '@@room/ADD_EXTRA_DEVICES_REQUEST',
  ADD_EXTRA_DEVICES_SUCCESS = '@@room/ADD_EXTRA_DEVICES_SUCCESS',
  ADD_EXTRA_DEVICES_ERROR = '@@room/ADD_EXTRA_DEVICES_ERROR',
  FETCH_EXTRA_DEVICES_REQUEST = '@@room/FETCH_EXTRA_DEVICES_REQUEST',
  FETCH_EXTRA_DEVICES_SUCCESS = '@@room/FETCH_EXTRA_DEVICES_SUCCESS',
  FETCH_EXTRA_DEVICES_ERROR = '@@room/FETCH_EXTRA_DEVICES_ERROR',
  SET_IS_POLLING = '@@room/SET_IS_POLLING',
}

export enum RoomsActionTypes {
  FETCH_REQUEST = '@@rooms/FETCH_REQUEST',
  FETCH_SUCCESS = '@@rooms/FETCH_SUCCESS',
  FETCH_ERROR = '@@rooms/FETCH_ERROR',
}

export interface RoomState {
  readonly loading: boolean
  readonly data: Room | null
  readonly isDesignerMode: boolean
  readonly isPolling: boolean
  readonly errors?: string
}

export interface RoomsState {
  readonly loading: boolean
  readonly data: Room[]
  readonly errors?: string
}
