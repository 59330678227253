import { UserActionTypes, UserState } from 'modules/user/state/types'
import { Reducer } from 'redux'

export const initUserState = {
  isLoggedIn: false,
  isAdmin: false,
}

export const UserReducer: Reducer<UserState> = (
  state = initUserState,
  action
): UserState => {
  const { type, payload } = action

  switch (type) {
    case UserActionTypes.LOGIN_REQUEST:
      return {
        ...state,
      }
    case UserActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
      }

    case UserActionTypes.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      }
    case UserActionTypes.SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: payload.isAdmin,
      }
    default:
      return state
  }
}
