import { ReactElement } from 'react'

export enum MessagesActionTypes {
  ADD_MESSAGE = '@@messages/ADD_MESSAGE',
  REMOVE_MESSAGE = '@@messages/REMOVE_MESSAGE',
  CLEAR_MESSAGES = '@@messages/CLEAR_MESSAGES',
}

export interface AppMessage {
  readonly id?: string
  body: string | ReactElement
  type: 'success' | 'warning' | 'danger'
}
export interface MessagesState {
  data: AppMessage[]
}
