import { Device } from 'types/device'

export enum DevicesActionTypes {
  FETCH_REQUEST = '@@devices/FETCH_REQUEST',
  FETCH_SUCCESS = '@@devices/FETCH_SUCCESS',
  FETCH_ERROR = '@@devices/FETCH_ERROR',
  REGISTER_REQUEST = '@@devices/REGISTER_REQUEST',
  REGISTER_SUCCESS = '@@devices/REGISTER_SUCCESS',
  REGISTER_ERROR = '@@devices/REGISTER_ERROR',
  EDIT_REQUEST = '@@devices/EDIT_REQUEST',
  EDIT_SUCCESS = '@@devices/EDIT_SUCCESS',
  EDIT_ERROR = '@@devices/EDIT_ERROR',
  REMOVE_REQUEST = '@@devices/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@@devices/REMOVE_SUCCESS',
  REMOVE_ERROR = '@@devices/REMOVE_ERROR',
  ADD_RELAYS_REQUEST = '@@devices/ADD_RELAYS_REQUEST',
  ADD_RELAYS_SUCCESS = '@@devices/ADD_RELAYS_SUCCESS',
  ADD_RELAYS_ERROR = '@@devices/ADD_RELAYS_ERROR',
  GIVE_TIP_REQUEST = '@@devices/GIVE_TIP_REQUEST',
  GIVE_TIP_SUCCESS = '@@devices/GIVE_TIP_SUCCESS',
  GIVE_TIP_ERROR = '@@devices/GIVE_TIP_ERROR',
}
export interface DevicesState {
  readonly loading: boolean
  readonly data: Device[]
  readonly errors?: string
}
