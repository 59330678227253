import { Reducer } from 'redux'
import {
  GameClockActionTypes,
  GameClockState,
} from 'modules/gameClock/state/types'

export const initGameClockState: GameClockState = {
  data: null,
  errors: undefined,
  loading: false,
}

const GameClockReducer: Reducer<GameClockState> = (
  state = initGameClockState,
  action
) => {
  switch (action.type) {
    case GameClockActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true }
    }
    case GameClockActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    case GameClockActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    case GameClockActionTypes.START_REQUEST: {
      return { ...state, loading: true }
    }
    case GameClockActionTypes.START_SUCCESS: {
      return { ...state, loading: false }
    }
    case GameClockActionTypes.START_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    case GameClockActionTypes.STOP_REQUEST: {
      return { ...state, loading: true }
    }
    case GameClockActionTypes.STOP_SUCCESS: {
      return { ...state, loading: false }
    }
    case GameClockActionTypes.STOP_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    case GameClockActionTypes.PAUSE_REQUEST: {
      return { ...state, loading: true }
    }
    case GameClockActionTypes.PAUSE_SUCCESS: {
      return { ...state, loading: false }
    }
    case GameClockActionTypes.PAUSE_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      }
    }
    case GameClockActionTypes.RESUME_REQUEST: {
      return { ...state, loading: true }
    }
    case GameClockActionTypes.RESUME_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case GameClockActionTypes.RESUME_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export { GameClockReducer }
