import {
  MessagesActionTypes,
  MessagesState,
} from 'modules/messages/state/types'
import { AnyAction } from 'redux'
import { v4 as uuid } from 'uuid'

export const initMessagesState = {
  data: [],
}

export const MessagesReducer = (
  state: MessagesState = initMessagesState,
  action: AnyAction
): MessagesState => {
  const { type, payload } = action

  switch (type) {
    case MessagesActionTypes.ADD_MESSAGE:
      return {
        ...state,
        data: [...state.data, { ...action.payload.message, id: uuid() }],
      }

    case MessagesActionTypes.REMOVE_MESSAGE:
      return {
        ...state,
        data: [
          ...state.data.filter(message => message.id !== payload.messageId),
        ],
      }
    case MessagesActionTypes.CLEAR_MESSAGES:
      return {
        ...state,
        data: [],
      }

    default:
      return state
  }
}
