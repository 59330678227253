import { GameClock } from 'types/gameClock'

export enum GameClockActionTypes {
  FETCH_REQUEST = '@@gameClock/FETCH_REQUEST',
  FETCH_SUCCESS = '@@gameClock/FETCH_SUCCESS',
  FETCH_ERROR = '@@gameClock/FETCH_ERROR',
  START_REQUEST = '@@gameClock/START_REQUEST',
  START_SUCCESS = '@@gameClock/START_SUCCESS',
  START_ERROR = '@@gameClock/START_ERROR',
  STOP_REQUEST = '@@gameClock/STOP_REQUEST',
  STOP_SUCCESS = '@@gameClock/STOP_SUCCESS',
  STOP_ERROR = '@@gameClock/STOP_ERROR',
  PAUSE_REQUEST = '@@gameClock/PAUSE_REQUEST',
  PAUSE_SUCCESS = '@@gameClock/PAUSE_SUCCESS',
  PAUSE_ERROR = '@@gameClock/PAUSE_ERROR',
  RESUME_REQUEST = '@@gameClock/RESUME_REQUEST',
  RESUME_SUCCESS = '@@gameClock/RESUME_SUCCESS',
  RESUME_ERROR = '@@gameClock/RESUME_ERROR',
}
export interface GameClockState {
  readonly loading: boolean
  readonly data: GameClock | null
  readonly errors?: string
}
