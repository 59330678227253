import { Reducer } from 'redux'
import { DevicesActionTypes, DevicesState } from 'modules/devices/state/types'

export const initDevicesState: DevicesState = {
  data: [],
  errors: undefined,
  loading: false,
}

const DevicesReducer: Reducer<DevicesState> = (
  state = initDevicesState,
  action
) => {
  switch (action.type) {
    case DevicesActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true }
    }
    case DevicesActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    case DevicesActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    case DevicesActionTypes.REGISTER_REQUEST: {
      return { ...state, loading: true }
    }
    case DevicesActionTypes.REGISTER_SUCCESS: {
      return { ...state, loading: false }
    }
    case DevicesActionTypes.REGISTER_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    case DevicesActionTypes.EDIT_REQUEST: {
      return { ...state, loading: true }
    }
    case DevicesActionTypes.EDIT_SUCCESS: {
      return { ...state, loading: false }
    }
    case DevicesActionTypes.EDIT_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    default: {
      return state
    }
  }
}

export { DevicesReducer }
